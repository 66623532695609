<template>
<div class="app-container with-content-offset-bottom pt-0">
  <RosterNav :loading="state == 'loading'" :failure="state == 'failure'" :backgroundLoading="backgroundLoading" />
  <div v-if="state == 'ready'">
    <v-expansion-panels accordion flat tile>
      <v-expansion-panel v-for="(r, i) in rankings" :key="i" active-class="active" @change="panelChange(r.ranking)">
        <v-expansion-panel-header ripple>
          <template v-slot:default="{ open }">
            <img class="rank-icon" :src="iconSrc(r.icon)" alt="">
            <div class="rank-header">
              <div class="d-flex flex-column flex-grow-1">
                <div class="rank-header-name">{{ r.name }}</div>
                <!-- <div v-if="!open" class="rank-header-desc">{{ r.desc }}</div> -->
              </div>
              <div class="rank-header-leaders">
                <v-slide-y-reverse-transition leave-absolute>
                  <div v-if="open" key="0" class="font-weight-regular">{{ r.desc }}</div>
                  <div v-else key="1">
                    <span v-for="(l, j) in topNames(r.ranking)" :key="j"> {{ l | capitalize }}</span>
                  </div>
                </v-slide-y-reverse-transition>
              </div>
            </div>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <table class="table" cellspacing="0">
            <tr v-for="({ rank, chars, value }, j) in r.ranking.slice(0, 10)" :key="j" class="table-row" :class="{ 'first': rank == 1, 'second': rank == 2, 'third': rank == 3 }">
              <td class="rank">
                <span v-if="rank <= 3"><v-icon>mdi-medal</v-icon></span>
                <span v-if="rank >= 4">#{{ rank }}</span>
              </td>
              <td class="value">
                {{ Number(value).toLocaleString('uk') }}
              </td>
              <td>
                <RosterList :members="topMembers(chars)" :classes="classes" class="mx-auto justify-start" />
              </td>
            </tr>
          </table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</div>
</template>

<script>

import { apiFetch } from '@/utils'
import RosterNav from '@/components/RosterNav'
import RosterList from '@/components/RosterList'

export default {
  components: { RosterNav, RosterList },
  data() {
    return {
      state: '?', // 'loading', 'failure', 'ready'
      backgroundLoading: false,
      rankings: [],
      classes: [],
      membersMap: {},
    }
  },
  created: function () {
    this.setPageMetadata('Лідери')
    this.state = 'loading'
    Promise.all([
      apiFetch('/rankings'),
      apiFetch('/classes'),
      apiFetch('/roster'),
    ]).then(([ rankings, classes, members ]) => {
      this.rankings = rankings
      this.classes = classes
      this.membersMap = members.reduce((a, c) => {
        const slug = `${c.name}-${c.realm}`.toLowerCase()
        a[slug] = { ...c, loadingStatus: 'loading' }
        return a
      }, {})
      this.state = 'ready'
    }).catch(() => this.state = 'failure')
  },
  methods: {
    iconSrc: function (icon) {
      return `https://render-eu.worldofwarcraft.com/icons/56/${icon}.jpg`
    },
    topNames: function (ranking, limit = 10) {
      const list = []
      for (let i = 0; i < Math.min(ranking.length, limit); i++) {
        const names = ranking[i].chars.slice(0, limit - list.length).map(e => e.split('-', 1)[0])
        list.push(...names)
      }
      return list
    },
    topMembers: function (chars) {
      return chars.map(s => this.membersMap[s])
    },
    panelChange: function (ranking, limit = 10) {
      const slugs = [ ...ranking.slice(0, limit).map(r => r.chars) ].reduce((a, c) => [ ...a, ...c ], [])
      this.backgroundLoading = true
      Promise.all(
        slugs.map(s => apiFetch(`/char/${s}`))
      ).then(profiles => {
        slugs.forEach((s, i) => {
          this.membersMap[s].avatar = profiles[i].avatar
          // this.membersMap[s].inset = profiles[i].inset
          this.membersMap[s].loadingStatus = 'done'
        })
        this.backgroundLoading = false
      })
    }
  }
}

</script>

<style scoped>

.v-expansion-panel {
  background: transparent !important;
}

.rank-icon {
  align-self: flex-start;
  flex: none !important;
  border-radius: 50%;
  width: 56px;
  height: 56px;
}

.rank-header {
  margin-left: 24px;
  margin-right: 24px;
  display: flex;
  flex-direction: column;
}

.rank-header-name {
  font: var(--font-text-bold);
  font-size: 20px;
  line-height: 1.2;
}

.rank-header-name:first-letter {
  margin-left: -0.05em;
}

.rank-header-desc {
  margin-top: .5em;
}

.rank-header-leaders {
  margin-top: .75em;
  font: var(--font-text-bold);
}

.rank-header-leaders span:not(:last-child)::after {
  content: '\F09DF';
  font-family: 'Material Design Icons';
  font-size: 16px;
  line-height: 0;
}

.rank-header-leaders span:nth-child(1) { opacity: 1; font-size: 130%; }
.rank-header-leaders span:nth-child(2) { opacity: .9; font-size: 120%; }
.rank-header-leaders span:nth-child(3) { opacity: .8; font-size: 110%; }
.rank-header-leaders span:nth-child(4) { opacity: .7; }
.rank-header-leaders span:nth-child(5) { opacity: .6; }
.rank-header-leaders span:nth-child(6) { opacity: .5; }
.rank-header-leaders span:nth-child(7) { opacity: .4; }
.rank-header-leaders span:nth-child(8) { opacity: .3; }
.rank-header-leaders span:nth-child(9) { opacity: .2; }
.rank-header-leaders span:nth-child(10) { opacity: .1; }

.table {
  margin: 0 -24px;
  width: calc(100% + 24px + 24px);
}

.table-row {
  vertical-align: top;
}

.table-row .rank {
  padding-top: 50px;
  width: 3em;
  line-height: 0;
  text-align: right;
}

.table-row .value {
  padding: 50px 1em 0;
  width: 4em;
  line-height: 0;
  font-weight: bold;
  font-size: 120%;
  text-align: right;
}

.table-row .rank .v-icon {
  position: absolute;
  margin-left: -32px;
  font-size: 300%;
  line-height: 0;
  text-shadow: 0 0 2px black;
  opacity: 0;
}

.v-expansion-panel.active .table-row .rank .v-icon {
  opacity: 1;
}

.table-row.first { background: #ffe7a0; }
.table-row.second { background: #e0e0e0; }
.table-row.third { background: #fed8b9; }

.table-row.first .rank .v-icon { color: gold; }
.table-row.second .rank .v-icon { color: silver; }
.table-row.third .rank .v-icon { color: #de9359; }

@media (min-width: 760px) {
  .rank-header-name {
    font-size: 26px;
  }

  .table-row .value {
    font-size: 150%;
  }
}

@media (min-width: 1040px) {
  .rank-header {
    flex-direction: row;
    align-items: center;
  }

  .rank-header-leaders {
    margin-top: 0;
    margin-left: 24px;
    width: 70%;
    min-width: 70%;
  }

  .table {
    margin-left: auto;
    width: calc(70% - 16px);
  }

  .table-row .value {
    font-size: 175%;
  }
}

</style>
